import { connect } from 'react-redux';

import {
    CategoryPageContainer as SourceCategoryPageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceRoute/CategoryPage/CategoryPage.container';
import CategoryReducer from 'Store/Category/Category.reducer';
import { withReducers } from 'Util/DynamicReducer';

import {
    GRID_LAYOUT,
    LIST_LAYOUT
} from './CategoryPage.config';

/** @namespace Scandipwa/Route/CategoryPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    attributeMetadata: state.ProductListInfoReducer.attributeMetadata
});

/** @namespace Scandipwa/Route/CategoryPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Scandipwa/Route/CategoryPage/Container */
export class CategoryPageContainer extends SourceCategoryPageContainer {
    state = {
        ...this.state,
        selectedLayoutType: window.location.pathname.indexOf('cigarfinder') > -1 ? LIST_LAYOUT : GRID_LAYOUT,
        isCigarFinderVisible: window.location.pathname.indexOf('cigarfinder') > -1
            || window.location.search.indexOf('customFilters') > -1
    };

    containerFunctions = {
        ...this.containerFunctions,
        setCigarFinderVisible: this.setCigarFinderVisible.bind(this)
    };

    setCigarFinderVisible(visible) {
        this.setState({ isCigarFinderVisible: visible });
    }

    containerProps() {
        const {
            attributeMetadata
        } = this.props;
        const {
            isCigarFinderVisible
        } = this.state;

        return {
            ...super.containerProps(),
            attributeMetadata,
            isCigarFinderVisible,
            isCigarFinderAvailable: this.isCigarFinderAvailable(),
            isCigarFinderPage: this.isCigarFinderPage()
        };
    }

    isCigarFinderPage() {
        return window.location.pathname.indexOf('cigarfinder') > -1;
    }

    isCigarFinderAvailable() {
        const {
            category
        } = this.props;

        if (category.id === undefined) {
            return null;
        }

        // temporary hardcoded for category id 3
        // eslint-disable-next-line no-magic-numbers
        return category.id === 3;
    }
}

export default withReducers({
    CategoryReducer
})(connect(mapStateToProps, mapDispatchToProps)(CategoryPageContainer));
