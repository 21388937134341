/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import PRODUCT_TYPE from 'Component/Product/Product.config';
import {
    AddToCartContainer as sourceAddToCartContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/AddToCart/AddToCart.container';
import {
    getMaxQuantity, getMinQuantity
} from 'Util/Product/Extract';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandipwa/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace Scandipwa/Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/* @namespace Scandipwa/Component/AddToCart/Container */
export class AddToCartContainer extends sourceAddToCartContainer {
    validateQuantity() {
        const {
            product, quantity, showNotification, product: { type_id: typeId }
        } = this.props;
        const minQty = getMinQuantity(product);
        const maxQty = getMaxQuantity(product);
        const inRange = quantity >= minQty && quantity <= maxQty;
        const isValid = typeId === PRODUCT_TYPE.grouped || inRange;
        const minQtyString = `${__('The minimum quantity for this product is ') + minQty }!`;
        const maxQtyString = `${__('The maximum quantity for this product is ') + maxQty }!`;

        if (!isValid) {
            if (minQty > maxQty) {
                showNotification('info', __('The requested qty is not available!'));
            } else if (quantity < minQty) {
                showNotification('info', minQtyString);
            } else if (quantity > maxQty) {
                showNotification('info', maxQtyString);
            }

            this.setState({ isAdding: false });

            return false;
        }

        return isValid;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
